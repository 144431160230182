import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {colors} from '../../theme/colors.styles'
import { useLocation, useNavigate } from 'react-router-dom';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  tabList: PropTypes.array.isRequired,
};



export default function BasicTabs(props) {
  const {tabList,setValue,value} = props;
  const navigate= useNavigate()
  const location = useLocation()
  const route = location?.pathname
  const handleChange = (event, newValue) => {
    setValue(newValue);
    console.log(location?.pathname.slice(1),newValue,'calc_');
  };

  

  return (
    <Box sx={{ width: '100%' }}>
      <Box >
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example"
         indicatorColor='pink'
        >
          {tabList?.map((tab) => (
              <Tab label={tab?.label} key={tab?.value} 
              sx={{
                color : colors?.text?.earthy,
                
                '&.Mui-selected': { 
                  color: colors?.text?.primary,
                },
                textTransform : 'none',
                fontSize : '1.2rem',
                fontWeight : 500,
                borderBottom : route === tab?.path ?  '3px solid #8E0D23' : ''
              }} 
              onClick={() => 
                  navigate(`${tab?.path}`)             
              } />
          ))}
        </Tabs>
      </Box>
      {/* {tabList?.filter((tab) => (
           <CustomTabPanel value={value} index={0}  key={tab?.value}>
           Item One
         </CustomTabPanel>
      ))} */}

    </Box>
  );
}