import { CircularProgress, Fade, Typography } from '@mui/material';
import React from 'react';


function Loader({
  style,
  variant,
  size,
  hideText,
  text,
  overlay,
  backgroundColor = 'rgba(256,256,256,0.4)',
}) {
  return (
    <Fade in>
      <div
        style={
          overlay
            ? {
                justifyContent: 'center',
                position: 'absolute',
                display: 'flex',
                width: '100%',
                height: '100%',
                flexDirection: 'column',
                top: 0,
                alignItems: 'center',
                right: 0,
                bottom: 0,
                zIndex: 2,
                backgroundColor,
                ...style,
              }
            : {}
        }
      >
        <CircularProgress variant={variant} size={size} />
        <Typography variant="caption" fontWeight={600} color='rgba(0, 0, 0, 0.87)' mt={2}>
          {!hideText && (text || 'Loading')}
        </Typography>
      </div>
    </Fade>
  );
}

export default Loader;
