import React, { Suspense } from 'react';
import Stack from '@mui/material/Stack';
import { Route, Routes } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import Loader from '../shared/components/Loader';
import { PATH } from '../shared/constants/path.constant';
import NotFoundPage from '../pages/NotFound/NotFound.page';
import HeaderComponent from '../pages/Home/components/HeaderComponent';
import AppFooter from '../shared/components/AppFooter';
import MobileHeader from '../pages/Home/Mobile/MobileHeader';


const HomePage  = React.lazy(() => import('../pages/Home/Home.page'));
const OurStoryPage = React.lazy(() => import('../pages/OurStory/ourStory.page'));
const GalleryPage = React.lazy(() => import('../pages/Gallery/Gallery.page'));
const CalendarPage = React.lazy(() => import('../pages/Calendar/Calendar.page'));
const AbousUsPage = React.lazy(() => import('../pages/AboutUs/AboutUs.page'));
const AdminPage = React.lazy(() => import('../pages/Admin/admin.page'));
const ContactUsPage = React.lazy(() => import('../pages/ContactUs/ContactUs.page'));
const TermsNCondnsPage = React.lazy(() => import('../pages/TermsNConditions/Terms.page'));
const PrivacyPolicyPage = React.lazy(() => import('../pages/PrivacyPolicy/PrivacyPolicy.page'));
const CancellationNRefundPage = React.lazy(() => import('../pages/Cancellation/Cancellation.page'));
const ShippingNDeliveryPage = React.lazy(() =>
 import('../pages/ShippingNDelivery/ShippingNDelivery.page'));

const routes = [
    {
      path: `${PATH?.DASHBOARD}`,
      name: 'Dashboard',
      component: HomePage,
      index: true,
    },
    {
        path: `${PATH?.OUR_STORY}`,
        name: 'OurStory',
        component: OurStoryPage,
        index: true,
      },
    {
        path: `${PATH?.GALLERY}`,
        name: 'Gallery',
        component: GalleryPage,
        index: true,
      },
      {
        path: `${PATH?.CALENDAR}`,
        name: 'Calendar',
        component: CalendarPage,
        index: true,
      },
      {
        path: `${PATH?.ABOUT_US}`,
        name: 'AboutUs',
        component: AbousUsPage,
        index: true,
      },
      {
        path: `${PATH?.ADMIN}`,
        name: 'Admin',
        component: AdminPage,
        index: true,
      },
      {
        path: `${PATH?.CONTACT_US}`,
        name: 'ContactUs',
        component: ContactUsPage,
        index: true,
      },
      {
        path: `${PATH?.TERMS_AND_CONDITIONS}`,
        name: 'TermsNConditions',
        component: TermsNCondnsPage,
        index: true,
      },
      {
        path: `${PATH?.PRIVACY_POLICY}`,
        name: 'PrivacyPolicy',
        component: PrivacyPolicyPage,
        index: true,
      },
      {
        path: `${PATH?.CANCELLATION_AND_REFUND}`,
        name: 'CancellationNRefund',
        component: CancellationNRefundPage,
        index: true,
      },
      {
        path: `${PATH?.SHIPPING_AND_DELIVERY}`,
        name: 'ShippingNDelivery',
        component: ShippingNDeliveryPage,
        index: true,
      },
  ];
  



function PublicRoutes() {
    return (
      <Routes>
        {routes.map((route) => (
          <Route
            key={`pubroute-${route.path}`}
            name={route.name}
            path={route.path}
            index={route.index}
            element={
              <Suspense fallback={<Loader overlay />}>
                <Stack p='1rem 1.5rem' spacing={5}>
                {isMobile ? <MobileHeader />  : <HeaderComponent />}
                <route.component />
                <AppFooter />
                </Stack>
              </Suspense>
            }
          />
        ))}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    );
  }
  export default PublicRoutes
  