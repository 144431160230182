import React from "react"
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import { isMobile } from 'react-device-detect';
import Typography from "@mui/material/Typography";
import { colors } from "../../theme/colors.styles";
import FooterLogo from '../../assets/logos/FooterLogo.svg'
// import Instagram from '../../assets/icons/instagram.svg'
import Facebook from '../../assets/icons/facebook.svg'
import Youtube from '../../assets/icons/youtube.svg'
import { Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PATH } from "../constants/path.constant";


const AppFooter = () => {

  const navigate = useNavigate()

  const footerLinks = [{id : 0, text : 'About Us',link : PATH?.ABOUT_US },
  {id : 1, text : 'Contact Us',link : PATH?.CONTACT_US},
 {id : 3, text : 'Temple Location',link : 'https://maps.app.goo.gl/zogKCKuuuVjCYCVw9'},
 { id : 4, text : 'Terms and Conditions',link : PATH?.TERMS_AND_CONDITIONS},
 {id : 5, text : 'Privacy policy',link : PATH?.PRIVACY_POLICY},
 {id : 6, text : 'Cancellation and Refund',link : PATH?.CANCELLATION_AND_REFUND}, 
//  {id : 7, text : 'Shipping and Delivery',link :'/shipping-and-delivery'},
]

const locationMethod = () => window.open('https://maps.app.goo.gl/u5n14B32Vh73E56P9', '_blank')

const DesktopFooter = () => (
    <>
      <Box display='flex' justifyContent='start' mb={1}>
        <img src={FooterLogo} style={{height : '2rem'}} alt="footer" loading="lazy" />
      </Box>
      <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
        <Typography variant="p" color='white' fontSize='1rem'>
          Sri Sri Sri Bhaguvan Sadh Charitable Trust</Typography>
          <Stack display='flex' flexDirection='row' alignItems='center' gap={1}>
              {footerLinks?.map((link,index) => 
                <Typography variant="p" color='white' fontSize='0.8rem' key={index} 
                borderRight= {index !== footerLinks?.length - 1 ? '1px solid white' : ''}  
                paddingRight={1} sx={{cursor: 'pointer'}} 
                onClick={() => index === 2 ? locationMethod() : navigate(`${link?.link}`)}  >
                {link?.text}</Typography>
                
              )}
          </Stack>
      </Box> 
    </>
)

const MobileFooter = () => (
  <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column' mt={2}>
    <img src={FooterLogo} style={{height : '2rem'}} alt="footer" loading="lazy" />
    <Typography variant="p" mt={1} color='white' fontSize='0.9rem'>
          Sri Sri Sri Bhaguvan Sadh Charitable Trust</Typography>
  </Box>
)


  return (
    <Stack sx={{backgroundColor : colors?.text?.primary,
     padding : {sm : '1.5rem', md : '2rem',lg : '3rem'}}}>
      
      {isMobile ? MobileFooter() : DesktopFooter()}
      <Divider sx={{borderColor : 'white',m : '2rem 0'}} />
      {isMobile && (
        <Box p='0 1rem' display='flex' flexWrap='wrap' gap={2} mb={4}>
          {
            footerLinks?.map((link,index) => 
            <Typography variant="p" color='white' fontSize='0.8rem' key={index} 
             sx={{cursor: 'pointer'}} flexWrap='wrap'
             onClick={() => index === 2 ? locationMethod() : navigate(`${link?.link}`)}  >
            {link?.text}</Typography>
            
          )
          }
        
        </Box>
      )}
      <Box display='flex' justifyContent='center' sx={{gap : {xs : '1rem',sm : '1.5rem',
      md : '2rem', lg : '3rem'}}}>
      {/* <img src={Instagram} style={{height : '2rem'}} loading="lazy" /> */}
      <img src={Facebook} style={{height : '2rem'}} alt="facebook-icon" loading="lazy" />
      <a href='https://www.youtube.com/@sribhaguvansadhmaharaj118' 
      target="_blank" rel="noreferrer" >
        <img src={Youtube} style={{height : '2rem'}} alt="youtube-icon" loading="lazy"  /></a>
      </Box>
      <Typography variant="p" color='white' fontSize='1rem' textAlign='center' mt={1} 
      mb={isMobile ? '1.6rem' : '0'}>
      © Copyright. All  rights reserved</Typography>
    </Stack>
  )
};

export default AppFooter;
