/* eslint-disable max-len */
import { createTheme } from '@mui/material';
import { colors } from './colors.styles';
// import { colors } from './colors.styles';
// import { FONT, MuiCssBaseline } from './font';


export const theme = createTheme({
//   palette: colors,
  spacing: 8,
  typography: {
    fontFamily: 'EB Garamond, Arial',
    // caption: {
    //   color: colors.text.caption,
    //   fontSize: '0.875rem',
    //   fontFamily: FONT.w400,
    //   lineHeight: '1.25rem',
    // },
    header : {
      color : colors?.text?.primary,
      fontSize : '30px',
      fontFamily : 'EB Garamond',
      lineHeight :' 1.125rem',
      fontWeight : 'bold'
    },
    subheader : {
      color : colors?.text?.primary,
      fontSize : '20px',
      fontFamily : 'Arial',
      lineHeight :' 1.5rem',
      fontWeight : 500
    },
    caption : {
      color : colors?.text?.primary,
      fontSize : '20px',
      fontFamily : 'Arial',
      lineHeight :' 1.5rem',
      fontWeight : '600'
    },
    p : {
      color : colors?.text?.primary,
      fontSize : '20px',
      fontFamily : 'EB Garamond',
      lineHeight :'1.125rem',
      fontWeight : 500
    }


    // h1: {
    //   fontFamily: FONT.w700,
    //   fontSize: '1.714rem',
    // },
    // h2: {
    //   fontFamily: FONT.w600,
    //   fontSize: '1.571rem',
    // },
    // h3: {
    //   fontFamily: FONT.w400,
    //   fontSize: '1.143rem',
    // },
    // h4: {
    //   fontFamily: FONT.w400,
    //   fontSize: '1rem',
    // },
    // h5: {
    //   fontFamily: FONT.w400,
    //   fontSize: '0.857rem',
    // },
    // h6: {
    //   fontFamily: FONT.w300,
    //   fontSize: '0.857rem',
    // },
  },
//   components: {
//     MuiCssBaseline,
//   },
});
