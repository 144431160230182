import React, { useEffect } from "react"
// import axios from "axios";
import Logo from '../../../assets/logos/Logo.svg'
import ToggleButton from "./ToggleButton";
import { useNavigate } from "react-router-dom";
import '../../../App.scss'
// import DonateNowButton from "./DonateNowButton";
// import { colors } from "../../../theme/colors.styles";


/* eslint-disable max-len */

const HeaderComponent = () => {
  const navigate = useNavigate()

//   function loadScript(src) {
//     return new Promise((resolve) => {
//         const script = document.createElement("script");
//         script.src = src;
//         script.onload = () => {
//             resolve(true);
//         };
//         script.onerror = () => {
//             resolve(false);
//         };
//         document.body.appendChild(script);
//     });
// }

// async function loadScriptIntoForm(src, formId) {
//   return new Promise((resolve, reject) => {
//       const script = document.createElement("script");
//       script.src = src;
//       script.onload = () => {
//           resolve(true);
//       };
//       script.onerror = (error) => {
//           reject(error);
//       };
//       const form = document.getElementById(formId);
//       if (form) {
//           form.appendChild(script);
//       } else {
//           reject(new Error(`Form with ID "${formId}" not found`));
//       }
//   });
// }


// async function displayRazorpay() {
//     const res = await loadScript(
//         "https://checkout.razorpay.com/v1/checkout.js"
//     );

//     if (!res) {
//         alert("Razorpay SDK failed to load. Are you online?");
//         return;
//     }

//     const result = await axios.post("http://localhost:5001/order");

//     if (!result) {
//         alert("Server error. Are you online?");
//         return;
//     }

//     const { id: order_id } = result.data;

//     // const options = {
//     //     key:'rzp_test_dsDuJlEALFdkuB', // Enter the Key ID generated from the Dashboard
//     //     amount: 10 * 100,
//     //     currency: 'INR',
//     //     name: " Sri Sri Sri Bhaguvan Sadh Charitable Trust",
//     //     description: "Test Transaction", 
//     //     image: Logo,
//     //     // order_id: order_id,
//     //     // handler: async function (response) {
//     //     //     const data = {
//     //     //         orderCreationId: order_id,
//     //     //         razorpayPaymentId: response.razorpay_payment_id,
//     //     //         razorpayOrderId: response.razorpay_order_id,
//     //     //         razorpaySignature: response.razorpay_signature,
//     //     //     };
//     //     //     // const result = await axios.post("http://localhost:5000/payment/success", data);

//     //     //     // alert(result.data.msg); 
//     //     // },
//     //     prefill: {
//     //         name: "Soumya Dey",
//     //         email: "SoumyaDey@example.com",
//     //         contact: "9999999999",
//     //     },
//     //     notes: {
//     //         address: "Soumya Dey Corporate Office",
//     //     },
//     //     theme: {
//     //         color: colors?.text?.primary,
//     //     },
//     // };

//     const options = {
//         key: 'rzp_test_dsDuJlEALFdkuB', // Your Razorpay API Key
//         amount: 1000, // Amount in paisa
//         currency: 'INR', // Currency
//         name: 'Sri Sri Sri Bhaguvan Sadh Charitable Trus', // Name of your company or website
//         description: 'Donation', // Description of the payment
//         order_id: order_id, // Order ID generated by your backend
//         handler:async function (response) {
//           // Handle success callback
//           console.log('Payment success:', response);
//           try {
// 					const verifyUrl = "http://localhost:5001/verify";
// 					const { data } = await axios.post(verifyUrl, response);
// 					console.log(data);
// 				} catch (error) {
// 					console.log(error);
// 				}
//         },
        
//         theme: {
//           color: '#F37254', // Theme color
//         },
        
//       };

//     const paymentObject = new window.Razorpay(options);
//     paymentObject.open();
// }

// async function displayRazorpay() {
//   const res = await loadScript(
//       "https://checkout.razorpay.com/v1/checkout.js"
//   );

//   if (!res) {
//       alert("Razorpay SDK failed to load. Are you online?");
//       return;
//   }

//   const result = await axios.post("http://localhost:5001/order");

//   if (!result) {
//       alert("Server error. Are you online?");
//       return;
//   }

//   const { id: order_id } = result.data;

//   const options = {
//       key: 'rzp_test_YEuVRsEULe8Qiz', // Your Razorpay API Key
//       amount: 1000, // Amount in paisa
//       currency: 'INR', // Currency
//       name: 'Sri Sri Sri Bhaguvan Sadh Charitable Trus', // Name of your company or website
//       description: 'Donation', // Description of the payment
//       order_id: order_id, // Order ID generated by your backend
//       prefill: {
//         email: 'customer@example.com', // Prefill email if available
//         contact: '9999999999', // Prefill mobile number if available
//     },
//       // handler: async function (response) {
//       //     // Handle success callback
//       //     console.log('Payment success:', response);
//       //     try {
//       //         const verifyUrl = "http://localhost:5001/verify";
//       //         const { data } = await axios.post(verifyUrl, response);
//       //         console.log(data);
//       //     } catch (error) {
//       //         console.log(error);
//       //     }
//       // },
//       handler: async function (response) {
//         // Handle success callback
//         console.log('Payment success:', response);
//         try {
//             const verifyUrl = "http://localhost:5001/verify";
//             const { data } = await axios.post(verifyUrl, response);
//             console.log(data);
    
//             // Extract customer details if available
//             let name = '';
//             let email = '';
//             let contact = '';
    
//             if (response && response.payment && response.payment.entity && response.payment.entity.contact) {
//                 name = response.payment.entity.contact.name || '';
//                 email = response.payment.entity.contact.email || '';
//                 contact = response.payment.entity.contact.contact || '';
//             }
    
//             // Update prefill with customer details
//             options.prefill = { name, email, contact };
    
//             // Reopen Razorpay with updated options
//             const paymentObject = new window.Razorpay(options);
//             paymentObject.open();
//         } catch (error) {
//             console.log(error);
//         }
//     },
    
//       theme: {
//           color: '#F37254', // Theme color
//       },
//   };

//   const paymentObject = new window.Razorpay(options);
//   paymentObject.open();
// }


// console.log(displayRazorpay())


// async function displayRazorpay() {
//      await loadScriptIntoForm(
//         "https://checkout.razorpay.com/v1/payment-button.js",'myForm'
//     );
// }



useEffect(() => {
  const rzpPaymentForm = document.getElementById("rzp_payment_form");
  
  if (!rzpPaymentForm.hasChildNodes()) {

    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/payment-button.js";
    script.async = true;
    script.dataset.payment_button_id = "pl_O4vDB1SH3HmCKf";
    rzpPaymentForm.appendChild(script);

  }

});




  return (
    <div style={{display : 'flex',
    justifyContent : 'space-between',
    alignItems : 'center',
      position: 'sticky',
      top: 0,
      zIndex: 1,
      backgroundColor : 'white',
      padding : '0.5rem'
  }}>
    
       <img src={Logo} style={{height : '2rem',cursor : 'pointer'}} alt="logo" loading="lazy"  onClick={() => navigate('/')} /> 
       <ToggleButton />
       {/* <DonateNowButton  /> */}
       <form id="rzp_payment_form"></form>
       {/* <form id="myForm" data-payment_button_id="pl_NzGipnECnCjkg9" ><DonateNowButton onClick={displayRazorpay} /></form> */}
       {/* <form><script src="https://checkout.razorpay.com/v1/payment-button.js" data-payment_button_id="pl_NzGipnECnCjkg9" async> </script> </form> */}
    </div>
  )
};

export default HeaderComponent;
