import { isMobile } from 'react-device-detect';

export const colors = {
  type: 'light',
  common: {
    black: '#2e384d',
    white: '#fff',
    lightBlue: '#F0F1FD',
    grey: '#F8F8F8',
    darkBlack: '#000000',
    dividerDark: '#E5E1E5',
    lightGrey: '#F5F5F5',
  },
  divider: '#F0F1FD',
  primary: {
    main: '#004EEB',
    light: 'rgba(165, 166, 246, 0.6)',
  },
  secondary: {
    main: '#777777',
    dark: '#D9D9D9',
  },
  info: {
    main: '#75E7C9',
  },
  warning: {
    main: '#E08A78',
    contrastText: '#ffffff',
  },
  error: {
    main: '#D6002A',
    contrastText: '#ffffff',
  },
  success: {
    main: '#80B042',
    contrastText: '#ffffff',
  },
  light: {
    main: '#ffffff',
    contrastText: '#000000',
  },

  background: {
    default: isMobile ? '#FAFAFA' : '#FFFFFF',
    main: '#4BA5B3',
  },
  text: {
    primary: '#8E0D23',
    secondary: '#475467',
    caption: '#475467',
    contrastText: '#ffffff',
    disabled: '#787486',
    label: '#5D5E74',
    title: '#A3AED0',
    value: '#2B3674',
    earthy : '#604F3B'
  },
  link: {
    primary: '#6956E5',
    secondary: '#4BA5B3',
  },
  hover: {
    default: '#F5F8FE',
  },
  table: {
    default: '#ffffff',
    odd: '#F7F7F7',
    head: {
      color: '#000000',
      backgroundColor: 'rgba(165, 166, 246, 0.41)',
    },
  },
  NoData: {
    bgcolor: '#FEFAFF',
    text: '#A39EBE',
    btn: '#E7E7E7',
    btnText: '#80807F',
  },
  myProject : {
    card : '#A58E4B'
  }
};
