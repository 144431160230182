export const PATH = {
    DASHBOARD: '/',
    OUR_STORY : '/our-story',
    ABOUT_US : '/about-us',
    GALLERY : '/gallery',
    CALENDAR : '/calendar',
    ADMIN : '/admin',
    CONTACT_US : '/contact-us',
    TERMS_AND_CONDITIONS : '/terms-and-conditions',
    PRIVACY_POLICY : '/privacy-policy',
    CANCELLATION_AND_REFUND : '/cancellation-and-refund',
    SHIPPING_AND_DELIVERY : '/shipping-and-delivery'
  };
  