import React, { useEffect, useState } from "react"
import AppTabs from "../../../shared/components/AppTabs";
import { headerTabList } from "../constants";


const ToggleButton = () => {

const [activeTab, setActiveTab] = useState('');




useEffect(() => {
  console.log();
},[activeTab])

console.log('log_2',activeTab);
  return (
    <div> 
        <AppTabs value={activeTab} setValue={setActiveTab} tabList={headerTabList}  
          />
    </div>
  )
};

export default React.memo(ToggleButton);
