/* eslint-disable max-len */

import { PATH } from "../../shared/constants/path.constant";


export const textDefinations = {
    header : 'Sri Sri Sri Bhaguvan Sadh Charitable Trust',
    caption : 'Our story is one of reverence and dedication, rooted in the legacy of our great-grandfathers who laid the foundation stones of these hallowed landmarks. At SBSCT, we embark on a heartfelt journey to preserve and revitalize the sacred temples that stand as testament to our rich cultural heritage. ',
    ourStoryText : 'Generations ago, our ancestors envisioned temples that would serve as sanctuaries of spirituality and community. Today, we inherit their vision and embrace the responsibility to safeguard and develop these timeless treasures. However, the task is monumental, and we recognise that we cannot embark on this journey alone.',
    storyTextTwo : 'Our great-grandfather, a revered figure in our village, was not only a successful entrepreneur but also a paragon of generosity and altruism. Inspired by a divine vision from Lord Venkateshwara, he journeyed alone into the forest to construct a small temple, symbolizing his unwavering faith and devotion. Despite his prosperous business ventures and contributions to governmental initiatives, he chose to spend his final years in solitude, caring for the temples he built. His legacy of compassion and selflessness endures, a testament to his profound impact on the community and his unwavering dedication to spiritual ideals.',
    templeDescription : 'Our cherished temple, erected in the 1880s by our grandfather, stands as a timeless testament to devotion and community welfare. Dedicated to Lord Venkateshwara, its sacred presence has been a source of solace and spiritual nourishment for generations in our village. Built with a vision for the greater good, it continues to inspire reverence and unity among all who seek its blessings.',
    supportText : `Our temples, steeped in history and reverence, stand as pillars of our cultural heritage. However, as devotees face challenges in their journey to seek solace and connection with the divine, it becomes imperative to develop and maintain these sacred sites. Your generous donations not only aid in the physical development of the temples but also serve as a commitment to preserving our rich culture and traditions. Together, let's ensure that these sanctuaries remain accessible and inspiring for generations to come.`,
    directionText1 : `If you're coming from Madhimadugu Road after Ippalapalli`,
    directionText2 : `follow these steps to reach our temple:`,
    directionText3 : `Head towards the forest checkpost - Take a left turn at the forest checkpost - Proceed straight for approximately 10 kilometers - You'll reach Sagar Back Waters.`,
    directionText4 : `
    Our temple is located straight ahead from there.
    We look forward to welcoming you to our temple!`
}


export const headerTabList =  [
    { label: 'Our story', value: 'OURSTORY',path : PATH?.OUR_STORY },
    { label: 'Gallery', value: 'GALLERY',path : PATH?.GALLERY },
    { label: 'Calendar', value: 'CALENDAR',path : PATH?.CALENDAR },
    { label: 'About Us', value: 'ABOUTUS',path : PATH?.ABOUT_US },
  ];
  