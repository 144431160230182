import './App.scss';

import React from 'react';
import PublicRoutes from './routes/public.routes';

function App() {
  // const { token } = useAuthCheck();

  return <div className="App"> <PublicRoutes /></div>;
}

export default App;

