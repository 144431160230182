import React from "react"
import Typography from '@mui/material/Typography'
import { Drawer } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box'
import List from '@mui/material/List';
// import Link from '@mui/material/Link';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import MenuIcon from '@mui/icons-material/Menu';
import { colors } from "../../theme/colors.styles";
import { PATH } from "../constants/path.constant";


const AppDrawer = () => {
  const navigate = useNavigate()
    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (newOpen) => () => {
      setOpen(newOpen);
    };

    const drawerList = [ {text :'Home',path : PATH?.DASHBOARD } , 
                    {text :'Our Story',path : PATH?.OUR_STORY } ,
                    {text :'Gallery',path : PATH?.GALLERY } ,
                    {text :'Calendar',path : PATH?.CALENDAR } ,
                    {text :'About Us',path : PATH?.ABOUT_US } , 
    ]

  return (
    <div>
        <MenuIcon style={{cursor : 'pointer',marginTop : '0.5rem'}} onClick={toggleDrawer(true)} />
      <Drawer open={open} onClose={toggleDrawer(false)}  >
         <Box width={150}>
            <List>
            {drawerList?.map((text) => (
          <ListItem key={text} disablePadding >
            <ListItemButton>
              <Typography color={colors?.text?.primary}  
              onClick={() => navigate(`${text?.path}`)}
              >{text?.text}</Typography>
                {/* <Link href={text?.path} underline="none" 
                color={colors?.text?.primary}>{text?.text}</Link> */}
            </ListItemButton>
          </ListItem>
        ))}
            </List>
         </Box>
      </Drawer>
    </div>
  )
};

export default AppDrawer;
