import React, { useEffect } from "react"
import Box from '@mui/material/Box'
import { useNavigate } from 'react-router-dom';
import AppDrawer from "../../../shared/components/AppDrawer";
// import DonateNowButton from "../components/DonateNowButton";
import Logo from '../../../assets/logos/Logo.svg'
import { Divider } from "@mui/material";
import '../../../App.scss'

const MobileHeader = () => {
  const navigate = useNavigate()


  useEffect(() => {
    const rzpPaymentForm = document.getElementById("rzp_payment_form");
    
    if (!rzpPaymentForm.hasChildNodes()) {
  
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/payment-button.js";
      script.async = true;
      script.dataset.payment_button_id = "pl_O4vDB1SH3HmCKf";
      rzpPaymentForm.appendChild(script);
  
    }
  
  });
  
  return (
    <Box sx={{position: 'sticky',
    top: 0,
    zIndex: 1,backgroundColor : 'white',pt : 1}}>
    <Box display='flex' justifyContent='space-between' alignItems='center' >
    <AppDrawer  />
    <img src={Logo} style={{height : '1.5rem',cursor : 'pointer'}} alt="header"
     onClick={() => navigate('/')} loading="lazy"  /> 
    {/* <DonateNowButton /> */}
    <form id="rzp_payment_form"></form>
    </Box>
    <Divider sx={{ boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',mt: '0.5rem' }} />
    </Box>
  )
};

export default MobileHeader;
