import React from "react"

const NotFoundPage = () => {
  return (
    <div>
        There is no data Something went Wrong!
    </div>
  )
};

export default NotFoundPage;
